import React, { useState, useEffect } from "react";
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter
} from "react-router-dom"
import GaugeComponent from 'react-gauge-component'
import CanvasJSReact from "./canvasjs.react";



var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var dps = [{ x: 1, y: 1.2 }];   //dataPoints.
var dps2 = [{ x: 1, y: -1.5 }];   //dataPoints.
var dps3 = [{ x: 1, y: 3 }];   //dataPoints.
var xVal = dps.length + 1;
var yVal = 15;
var xVal2 = dps.length + 1;
var yVal2 = 15;
var xVal3 = dps.length + 1;
var yVal3 = 15;
var updateInterval = 250;


var chart;
var chart2;


function getRandomNumber() {
    return Math.random() * 100;
}

export function getData() {
    return [
        ["Label", "Value"],
        ["Memory", getRandomNumber()],
        ["CPU", getRandomNumber()],
        ["Network", getRandomNumber()],
    ];
}

export const options = {
    width: 400,
    height: 120,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 75,
    yellowTo: 90,
    minorTicks: 5,
};

export function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<GaugeChart />} />
                <Route path="/line" element={<LineChart />} />
            </Routes>
        </BrowserRouter>
    )
}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

function updateChart() {
    var multiplier = getRandomIntInclusive(1,30);
  
    if (xVal % multiplier == 0 || xVal % (multiplier + 1) == 0 || xVal % (multiplier + 2) == 0) {
       
        yVal3 = 4 * getRandomIntInclusive(-1000,1000)/1000;
        yVal2 = 4 * getRandomIntInclusive(-1000, 1000) / 1000;
        yVal = 4 * getRandomIntInclusive(-1000, 1000) / 1000;
    }
    else {

        yVal3 = getRandomIntInclusive(-1000, 1000) / 1000;
        yVal2 = getRandomIntInclusive(-1000, 1000) / 1000;
        yVal = getRandomIntInclusive(-1000, 1000) / 1000;
    }
    if (yVal > 3 || yVal < -3) {
        dps.push({ x: xVal, y: yVal, indexLabel: "ALERT!!!", indexLabelFontColor: "white", indexLabelFontSize: 35, indexLabelBackgroundColor:"red" });
    } else {
        dps.push({ x: xVal, y: yVal }); 
    }
  
    xVal++;
    if (dps.length > 50) {
        dps.shift();
    }

    if (yVal2 > 3 || yVal2 < -3) {
        dps2.push({ x: xVal2, y: yVal2, indexLabel: "ALERT!!!", indexLabelFontColor: "white", indexLabelFontSize: 35, indexLabelBackgroundColor: "red" });
    } else {
        dps2.push({ x: xVal2, y: yVal2 });
    }
        xVal2++;
        if (dps2.length > 50) {
            dps2.shift();
        }
    

    if (yVal3 > 3 || yVal3 < -3 ) {
        dps3.push({ x: xVal3, y: yVal3, indexLabel: "ALERT!!!", indexLabelFontColor: "white", indexLabelFontSize: 35, indexLabelBackgroundColor: "red" });
    } else {
        dps3.push({ x: xVal3, y: yVal3 });
    }
    xVal3++;
    if (dps3.length > 50) {
        dps3.shift();
    }
    chart.render();
    chart2.render();


}

function LineChart() {
    const [data, setData] = useState(getData);
    const [searchParams, setSearchParams] = useSearchParams();

   

    
    setInterval(updateChart, updateInterval);

   

    const options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light1", // "light1", "dark1", "dark2"
 
        title: {
            text: "Actual acceleration data axis X [g]"
        },
        axisY: {
            title: "Acceleration",
            suffix: "g"
        },
        axisX: {
            title: "Sample",
            prefix: "",
            interval: 2
        },
        data: [{
            type: "line",
            showInLegend: true, 
            legendText: "X axis",
            toolTipContent: "Sample {x} Value {y} g",
            dataPoints: dps,
            lineColor: "green"

        }
            
        ]
    }


    const options2 = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light1", // "light1", "dark1", "dark2"

        title: {
            text: "Actual acceleration data axis Y [g]"
        },
        axisY: {
            title: "Acceleration",
            suffix: "g",
      
        },
        axisX: {
            title: "Sample",
            prefix: "",
            interval: 2
        },
        data: [
        {
            type: "line",
            showInLegend: true,
            legendText: "Y axis",
            toolTipContent: "Sample {x} Value {y} g",
                dataPoints: dps2,
                  lineColor: "red"
        }
        ]
    }

    console.log("Token: " + searchParams.get("token"));
    if (searchParams.get("token") === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ODc5MzI0NzUyIiwibmFtZSI6IkNoYXJ0cyIsImlhdCI6MTUxNjIzOTAyMn0.Djd9bqBnJv2OXI8bGei28E3osgwvpWbzIbDK3HtWix4") {
    return(
        <>
            <CanvasJSChart options={options}
            onRef={ref => chart = ref} 
            />
            <CanvasJSChart options={options2}
                onRef={ref => chart2 = ref}
            />
        </>
            
        );
    }
}

function GaugeChart() {
    const [data, setData] = useState(getData);
    const [searchParams, setSearchParams] = useSearchParams();



    useEffect(() => {
        const id = setInterval(() => {
            setData(getData());
        }, 3000);

        return () => {
            clearInterval(id);
        };
    });

    console.log("Token: " + searchParams.get("token"));
    if (searchParams.get("token") === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ODc5MzI0NzUyIiwibmFtZSI6IkNoYXJ0cyIsImlhdCI6MTUxNjIzOTAyMn0.Djd9bqBnJv2OXI8bGei28E3osgwvpWbzIbDK3HtWix4") {
        return (
            <>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-12">
                            <h1>Actual inclination difference from the initial state</h1>
                            <br></br>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-3">
                            <h1 class="d-flex justify-content-center">X - axis</h1>
                            <GaugeComponent
                                type="semicircle"
                                arc={{
                                    width: 0.2,
                                    padding: 0.005,
                                    cornerRadius: 1,
                                    // gradient: true,
                                    subArcs: [
                                        {
                                            limit: -2,
                                            color: '#EA4228',
                                            showTick: true,
                                            tooltip: {
                                                text: 'alert value'
                                            },
                                            onClick: () => console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"),
                                            onMouseMove: () => console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB"),
                                            onMouseLeave: () => console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"),
                                        },
                                        {
                                            limit: -1,
                                            color: '#F5CD19',
                                            showTick: true,
                                            tooltip: {
                                                text: 'warning value'
                                            }
                                        },
                                        {
                                            limit: 1,
                                            color: '#5BE12C',
                                            showTick: true,
                                            tooltip: {
                                                text: 'value in limit'
                                            }
                                        },
                                        {
                                            limit: 2, color: '#F5CD19', showTick: true,
                                            tooltip: {
                                                text: 'warning value'
                                            }
                                        },
                                        {
                                            color: '#EA4228',
                                            tooltip: {
                                                text: 'alert value'
                                            }
                                        }
                                    ]
                                }}
                                pointer={{
                                    color: '#345243',
                                    length: 0.80,
                                    width: 15,
                                    elastic: true,
                                }}
                                labels={{
                                    valueLabel: { formatTextValue: value => value + 'º' },
                                    tickLabels: {
                                        type: 'outer',
                                        valueConfig: { formatTextValue: value => value + 'º', fontSize: 10 },
                                        ticks: [
                                            { value: -2 },
                                            { value: -1 },
                                            { value: 0 }
                                        ],
                                    }
                                }}
                                value={-0.3}
                                minValue={-3}
                                maxValue={3}
                            />
                        </div>
                        <div class="col-lg-3 col-3">
                            <h1 class="d-flex justify-content-center">Y - axis</h1>
                            <GaugeComponent
                                type="semicircle"
                                arc={{
                                    width: 0.2,
                                    padding: 0.005,
                                    cornerRadius: 1,
                                    // gradient: true,
                                    subArcs: [
                                        {
                                            limit: -2,
                                            color: '#EA4228',
                                            showTick: true,
                                            tooltip: {
                                                text: 'alert value'
                                            },
                                            onClick: () => console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"),
                                            onMouseMove: () => console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB"),
                                            onMouseLeave: () => console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"),
                                        },
                                        {
                                            limit: -1,
                                            color: '#F5CD19',
                                            showTick: true,
                                            tooltip: {
                                                text: 'warning value'
                                            }
                                        },
                                        {
                                            limit: 1,
                                            color: '#5BE12C',
                                            showTick: true,
                                            tooltip: {
                                                text: 'value in limit'
                                            }
                                        },
                                        {
                                            limit: 2, color: '#F5CD19', showTick: true,
                                            tooltip: {
                                                text: 'warning value'
                                            }
                                        },
                                        {
                                            color: '#EA4228',
                                            tooltip: {
                                                text: 'alert value'
                                            }
                                        }
                                    ]
                                }}
                                pointer={{
                                    color: '#345243',
                                    length: 0.80,
                                    width: 15,
                                    elastic: true,
                                }}
                                labels={{
                                    valueLabel: { formatTextValue: value => value + 'º' },
                                    tickLabels: {
                                        type: 'outer',
                                        valueConfig: { formatTextValue: value => value + 'º', fontSize: 10 },
                                        ticks: [
                                            { value: -2 },
                                            { value: -1 },
                                            { value: 0 }
                                        ],
                                    }
                                }}
                                value={-1.1}
                                minValue={-3}
                                maxValue={3}
                            /></div>
                        <div class="col-lg-3 col-3">
                            <h1 class="d-flex justify-content-center">Z - axis</h1>
                            <GaugeComponent
                                type="semicircle"
                                arc={{
                                    width: 0.2,
                                    padding: 0.005,
                                    cornerRadius: 1,
                                    // gradient: true,
                                    subArcs: [
                                        {
                                            limit: -2,
                                            color: '#EA4228',
                                            showTick: true,
                                            tooltip: {
                                                text: 'alert value'
                                            },
                                            onClick: () => console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"),
                                            onMouseMove: () => console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB"),
                                            onMouseLeave: () => console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"),
                                        },
                                        {
                                            limit: -1,
                                            color: '#F5CD19',
                                            showTick: true,
                                            tooltip: {
                                                text: 'warning value'
                                            }
                                        },
                                        {
                                            limit: 1,
                                            color: '#5BE12C',
                                            showTick: true,
                                            tooltip: {
                                                text: 'value in limit'
                                            }
                                        },
                                        {
                                            limit: 2, color: '#F5CD19', showTick: true,
                                            tooltip: {
                                                text: 'warning value'
                                            }
                                        },
                                        {
                                            color: '#EA4228',
                                            tooltip: {
                                                text: 'alert value'
                                            }
                                        }
                                    ]
                                }}
                                pointer={{
                                    color: '#345243',
                                    length: 0.80,
                                    width: 15,
                                    elastic: true,
                                }}
                                labels={{
                                    valueLabel: { formatTextValue: value => value + 'º' },
                                    tickLabels: {
                                        type: 'outer',
                                        valueConfig: { formatTextValue: value => value + 'º', fontSize: 10 },
                                        ticks: [
                                            { value: -2 },
                                            { value: -1 },
                                            { value: 0 }
                                        ],
                                    }
                                }}
                                value={0.2}
                                minValue={-3}
                                maxValue={3}
                            /></div>
                    </div>
                </div>
            </>

        );
    }
}
